import React from 'react';
import AboutBelowContent from '../../Components/ExperienceBlocks/AboutBelowContent';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { INFO } from '../../common';


const Experience = () => {
    const headerData = {
        img: 'images/about/shadow_icon1.png',
        heading: 'first_choice',
        subheading: 'company_name',
    };

    const ExpericenData = [
        {
            id: '1',
            title: 'reliable',
            description: 'over_100'

        },
        {
            id: '2',
            title: 'efficient',
            description: 'perfect_efficient'
        },
    ];

    return (
        <div className="experience section" style={{ marginTop: -80 }}>
            <div className="container" style={{ marginBottom: 40 }}>
                <div className="row">
                    <div className="col-lg-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src="/images/Nguyen-Thanh-Tung.jpg" alt="Mr. Nguyễn Thanh Tùng"
                            style={{
                                borderRadius: '50%',
                                width: '100%',
                                height: 'auto',
                                border: '2px solid #f1f1f1',
                                boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                                backgroundColor: 'gray',
                                resizeMode: 'contain',
                            }} />
                    </div>
                    <div className="col-lg-8" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                        <h2 style={{ fontWeight: 'bold' }}><Trans>{"manager_name"}</Trans></h2>
                        <h5 style={{ color: 'red', marginTop: 25, marginBottom: 20, fontWeight: 'bold', fontSize: 20 }}><Trans>{"manager"}</Trans></h5>
                        <p style={{ fontSize: 17, color: '#656a7c', fontWeight: 500, lineHeight: '28px', textAlign: 'justify' }}><Trans>{"feeling"}</Trans></p>
                    </div>
                </div>
            </div>
            <div className="container">

                <div className="row">
                    <div className="col-lg-6">
                        <div className="group_image_holder type_1">
                            <div className="expe_box">
                                <div className="expe_box_inner">
                                    <h1>{INFO.experience}</h1>
                                    <p><Trans>{"years"}</Trans></p>
                                    <p><Trans>{"experience"}</Trans></p>
                                </div>
                            </div>
                            <div className="image_object">
                                <img src="/images/about/2_new.png" alt="" />
                                <div className="object">
                                    <img src="/images/about/3.png" alt="About" />
                                    <img src="/images/about/3.png" alt="About" />
                                    <img src="/images/about/3.png" alt="About" />
                                    <img src="/images/about/s1.png" alt="About" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="experience_content">
                            <div className="section_header">
                                {/* <div className="shadow_icon"><img src={headerData.img} alt="" /></div> */}
                                {/* <h6 className="section_sub_title">{headerData.subheading}</h6> */}
                                <h1 className="section_title"><Trans>{headerData.heading}</Trans></h1>
                                <p className="section_desc">
                                    <Trans>{"first_choice_1"}</Trans>
                                </p>
                                <p className="section_desc" style={{ marginTop: 10 }}>
                                    <Trans>{"first_choice_2"}</Trans>
                                </p>
                                <p className="section_desc" style={{ marginTop: 10 }}>
                                    <Trans>{"first_choice_3"}</Trans>
                                </p>
                                <div className="about_below">
                                    {
                                        ExpericenData.map(data =>
                                            <AboutBelowContent
                                                key={data.id}
                                                data={data}
                                            />
                                        )
                                    }

                                </div>
                            </div>
                            <Link className="button" to="/gioi-thieu"><Trans>{"learn_more"}</Trans></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default Experience;