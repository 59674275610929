import React from 'react';
import { Trans } from 'react-i18next';

const TestimonialCard = ({ data }) => {
    const { img, name, country, content, link, pdffile } = data;
    return (
        <div className="item">
            <div className="testibox1"  >
                <div className="testibox_inner">
                    <div className="testi-content">
                        <ul>
                            <li className="text">{<Trans>{"rating"}</Trans>}:</li>
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                            <li><i className="fa fa-star"></i></li>
                            {/* <li><i className="fa fa-star-o"></i></li> */}
                        </ul>
                        <p style={{ minHeight: 250 }}>{content}</p>
                    </div>
                    <div className="testi-top" >
                        {img && <div className="testi-img" style={{ width: 80, marginLeft: - 20, marginRight: 5, marginTop: -10 }}>
                            <img src={img} alt="" style={{ height: 60, width: 80 }} />
                        </div>}
                        <a href={link} target='_blank' style={{ flex: 1 }}>
                            <div className="testi-info">
                                <h4>{name}</h4>
                                <h6>{country}</h6>
                            </div>
                        </a>
                        <a href={`/pdf/${pdffile}`} target='_blank' style={{ zIndex: 3 }}>
                            <i className='fa fa-file-pdf-o' style={{ fontSize: 30 }} />
                        </a>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default TestimonialCard;